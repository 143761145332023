<template>
	<div>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<input v-model="price">
		<br>
		<div class='btn btn-primary' @click='decode_escrow_test()'>Lister le nft</div>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
	</div>
</template>

<script>

import { list_nft_program, cancel_list_program, buy_nft_program, cancel_make_offer_program, decode_escrow } from '@/libs/rustProgram.js';

import Wallet from '@project-serum/sol-wallet-adapter';

import {
  PublicKey,
} from '@solana/web3.js';


import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

import {getNftOwned} from '@/libs/solanaConnection.js'

export default {
  name: 'TestRust',
  components: {
  },
  data: function () {
	
	return {
		
		price: 0.5,
		amount: 0.5,
	}
  },
  props: {
   
  },
  
  methods: {
  
	decode_escrow_test: function() {
		
		decode_escrow();
		
	},
  
	verifie_escrow: function() {
		
		axios.get(process.env.VUE_APP_ROOT_API+'/maintenance/all_listed').then(function (result) {
			
			result.data.forEach(function(nft) {
				
				var escrow = nft.escrow_account;
				decode_escrow(nft.escrow_account);
			});
		});
		
	},
	
	testCreateAddociatedTokenAccount: function() {
		
		var mint = new PublicKey('4rn31yUtirmRgw9K8Gke3Mzp43nZmM1gdePdigX51wRo');
		
		window.solana.connect();
		
		window.solana.on("connect", () => {
			
			// CreateAddociatedTokenAccount(window.solana, mint);
		});
	},
	
	/*
	testCancelOfferVue: function() {
		
		var offer = {};
		
		offer.escrow_account = '562X7Y9HsATbG9ZEGeE1C5WGmpC1yNjg8kJJxs9EoKCj';
		offer.mint_address = 'Hh9UCpDE35tF728SNHdzZDR5oE45VqjBct3g2pMSamXP';
		
		
		
		window.solana.connect();
		
		window.solana.on("connect", () => {
			
			// cancel_list_program(window.solana, {mint_address:'9oR8CnV4ZLvYaaLMZioo7iqchwJqyeCdw9oaiBXbtuL7', escrow_account: '4Wb6xUCmUZEtGkDBTggFEsX8ixr6d4scNLudWnUNbr81'})
			// var escrow_account = '53zLGAxdz8aR3qn1NSynfXUgFKqeUBgR1KHCHEM8vbry';
			//list_nft_program(window.solana, price, '6V19x52fVmYtPex7iPrcHjbBKzCRYKywF4qS1gPZgZJP');
			// HQdmdACc5YnUJRDSwxhLFHx2MkHyrWLdyyyTKmQj3czQ
			// buy_nft_program(window.solana, price, '9oR8CnV4ZLvYaaLMZioo7iqchwJqyeCdw9oaiBXbtuL7');
			cancel_make_offer_program(window.solana, offer);
		});
	},
	*/
  
	/*
	testListingVue: function() {
		
		var price = this.price;
		
		window.solana.connect();
		
		window.solana.on("connect", () => {
			
			// cancel_list_program(window.solana, {mint_address:'9oR8CnV4ZLvYaaLMZioo7iqchwJqyeCdw9oaiBXbtuL7', escrow_account: '4Wb6xUCmUZEtGkDBTggFEsX8ixr6d4scNLudWnUNbr81'})
      var escrow_account = '53zLGAxdz8aR3qn1NSynfXUgFKqeUBgR1KHCHEM8vbry';
			//list_nft_program(window.solana, price, '6V19x52fVmYtPex7iPrcHjbBKzCRYKywF4qS1gPZgZJP');
			// HQdmdACc5YnUJRDSwxhLFHx2MkHyrWLdyyyTKmQj3czQ
			// buy_nft_program(window.solana, price, '9oR8CnV4ZLvYaaLMZioo7iqchwJqyeCdw9oaiBXbtuL7');
			buy_nft_program(window.solana, price, '6V19x52fVmYtPex7iPrcHjbBKzCRYKywF4qS1gPZgZJP', '53zLGAxdz8aR3qn1NSynfXUgFKqeUBgR1KHCHEM8vbry');
		});
	},
	testTransaction: function() {
	
		// let wallet = new Wallet(window.solana);
		
		var amount = this.amount * 1000000000;
		
		window.solana.connect();
		
		window.solana.on("connect", () => {
		
			var price = 0.5;
			var escrowAccount = 'DavNb4qJY1LSUzAXpYzZhADMbeErxLxRCzD6z233uF6v';
			var mint_address = '5G2KjnH9kSbaf24En2Z5nRtDWzuuDEmh29K8GxkVQrPD'; // <= c'est l'adresse du NFT (le mint address)
			
			testBuy(window.solana, price, mint_address, escrowAccount);
		});
	}
	*/
  },
  created: function() {
  
	
	this.verifie_escrow();
	
  },
}
</script>

